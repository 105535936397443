import React, { useState, useEffect } from 'react';
import './Products.css';
import { useProduct } from '../../context/ProductContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Products = () => {
   const navigate = useNavigate();
  const { cart, setCart,setProductToShow } = useProduct();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const [product] = useState([
    {
      id: "001",
      name: "McAfee Antivirus Basic",
      desc: "Comprehensive Protection Against Viruses and Malware. Shield your PC and data with our powerful blend of performance accuracy and strengthened protections that thwart cybercriminals.",
      price: "44.99",
      img: "https://i5.walmartimages.com/asr/90444650-f8ce-4948-a3dc-c13746f8e67c_1.f67a9157cafca47895910f565f77faad.jpeg?odnWidth=1000&odnHeight=1000&odnBg=ffffff"
    },
    {
      id: "005",
      name: "McAfee Total Protection",
      desc: "McAfee Total Protection offers powerful PC protection, including McAfee ActiveProtection™ and NetGuard™ technology to keep zero-day threats and botnets at bay. Additionally, it includes a spam and dangerous email filter to keep your inbox safe and parental controls to block inappropriate sites and set time limits for internet use.",
      price: "99.99",
      img: "https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png"
    },
    {
      id: "003",
      name: "Norton AntiVirus Plus",
      desc: "Basic protection for your PC or Mac. Norton AntiVirus Plus offers real-time threat protection against existing and emerging malware, including ransomware and viruses. It also includes a smart firewall to safeguard your personal data and device.",
      price: "39.99",
      img: "https://media.croma.com/image/upload/v1687940088/Croma%20Assets/Unclassified/Images/267107_rnxeoo.png"
    },
      {id:'004',name:"Kaspersky Internet Security",price:27.05,rating:"3.5",img:"https://5.imimg.com/data5/FO/OB/MY-51056009/kaspersky-internet-security--500x500.jpg"},
  ]);

  const handleNavigation = (item) =>{
   setProductToShow(item);
   const productName = item.name.toLowerCase();
    if (productName.includes("norton")) {
      navigate(`/norton/${item.name.toLowerCase().replace(/\s+/g, '-')}`);
    } else if (productName.includes("mcafee")) {
      navigate(`/mcafee/${item.name.toLowerCase().replace(/\s+/g, '-')}`);
    }else if (productName.includes("avast")){
      navigate(`/avast/${item.name.toLowerCase().replace(/\s+/g, '-')}`);
    }else if (productName.includes("kaspersky")){
      navigate(`/kaspersky/${item.name.toLowerCase().replace(/\s+/g, '-')}`);
    }
  }

  const handleCart = (item) => {
    if (!cart.some(bagItem => bagItem.id === item.id)) {
      setCart((prevBag) => [...prevBag, item]);
      toast.success(`${item.name} Added to Cart`);
    } else {
      toast.warning(`${item.name} is already in the Cart`);
    }
  };

  return (
    <div className='products'>
      <div className="productshead head">
        <h2>Best Sellers ↓</h2>
        <p>Explore Our Best Sellers</p>
      </div>
      <div className="productslist">
        <div className="productlistmain">
          {product.map((item) => (
            <div className="product" key={item.id}>
              <div className="productimg" onClick={()=>handleNavigation(item)}>
                <img src={item.img} alt={item.name} />
              </div>
              <div className="productdetails">
                <h2 onClick={()=>handleNavigation(item)}>{item.name}</h2>
                <p>$ {item.price}</p>
              </div>
              <div className="productbutton">
                <button className="buybutton" onClick={()=>navigate('/payment')}>Buy Now</button>
                {cart.some((prev)=> prev.id === item.id) ?( <button className="addbutton" >Added To Bag ✔</button>):(
                <button className="addbutton" onClick={() => handleCart(item)}>
                  <img src="https://img.icons8.com/ios-filled/50/FFFFFF/shopping-cart.png" alt="shopping-cart" />
                  <span>Add to Cart  →</span>
                </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
